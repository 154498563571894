import React from 'react';

import { ViewProps } from 'types/props';

function TimeView({ condition }: ViewProps) {
  return (
    <span>
      Time is {condition.beforeOrAfter} {condition.time}
    </span>
  );
}

export default TimeView;
