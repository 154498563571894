import React, { useState } from 'react';

import { Button, Card } from 'antd';

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ConditionEditor from 'components/ConditionEditor';
import { conditionsMap } from 'components/Conditions/conditionList';
import { ConditionListViewProps, ViewProps } from 'types/props';
import { Condition } from 'types/types';

function ConditionView(props: ViewProps) {
  const ConditionViewComponent: React.FC<ViewProps> =
    conditionsMap[props.condition.type].View;
  return (
    <Card>
      <ConditionViewComponent {...props} />
    </Card>
  );
}

function ConditionListView({
  conditionList,
  setConditionList,
  showAddCondition,
  addStoplossPercentCondition,
  setAddStoplossPercentCondition,
  setIsEditing,
  readonly,
}: ConditionListViewProps) {
  const [addingCondition, setAddingCondition] = useState<boolean>(false);
  const [editingConditionIndex, setEditingConditionIndex] = useState<
    number | null
  >(null);

  React.useEffect(() => {
    if (addStoplossPercentCondition) {
      setAddingCondition(true);
    }
  }, [addStoplossPercentCondition]);

  React.useEffect(() => {
    if (setIsEditing) {
      setIsEditing(editingConditionIndex !== null || addingCondition);
    }
  }, [editingConditionIndex, addingCondition]);

  return (
    <>
      {conditionList.conditions.map(
        (condition: Condition, conditionIndex: number) => (
          <div
            key={condition.id}
            style={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            {editingConditionIndex === conditionIndex ? (
              <ConditionEditor
                condition={condition}
                setCondition={(condition: Condition) => {
                  setConditionList({
                    ...conditionList,
                    conditions: [
                      ...conditionList.conditions.slice(0, conditionIndex),
                      condition,
                      ...conditionList.conditions.slice(conditionIndex + 1),
                    ],
                  });
                  setEditingConditionIndex(null);
                }}
                cancelEdit={() => {
                  setEditingConditionIndex(null);
                }}
                addStoplossPercentCondition={addStoplossPercentCondition}
                setAddStoplossPercentCondition={setAddStoplossPercentCondition}
              />
            ) : (
              <>
                <ConditionView condition={condition} />
                {!readonly ? (
                  <>
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      disabled={
                        editingConditionIndex !== null ||
                        addingCondition === true
                      }
                      onClick={() => {
                        setEditingConditionIndex(conditionIndex);
                      }}
                    />
                    <Button
                      type="primary"
                      danger
                      icon={<DeleteOutlined />}
                      disabled={
                        editingConditionIndex !== null ||
                        addingCondition === true
                      }
                      onClick={() => {
                        setConditionList({
                          ...conditionList,
                          conditions: [
                            ...conditionList.conditions.slice(
                              0,
                              conditionIndex,
                            ),
                            ...conditionList.conditions.slice(
                              conditionIndex + 1,
                            ),
                          ],
                        });
                      }}
                    />
                  </>
                ) : null}
              </>
            )}
          </div>
        ),
      )}
      {addingCondition ? (
        <ConditionEditor
          condition={null}
          setCondition={(condition: Condition) => {
            setConditionList({
              ...conditionList,
              conditions: [...conditionList.conditions, condition],
            });
            setAddingCondition(false);
          }}
          cancelEdit={() => {
            setAddingCondition(false);
          }}
          addStoplossPercentCondition={addStoplossPercentCondition}
          setAddStoplossPercentCondition={setAddStoplossPercentCondition}
        />
      ) : null}
      {!readonly && showAddCondition ? (
        <Button
          onClick={() => {
            setAddingCondition(true);
          }}
          disabled={editingConditionIndex !== null || addingCondition}
        >
          ADD CONDITION
        </Button>
      ) : null}
    </>
  );
}

export default ConditionListView;
