import React from 'react';

import { Button, Card, Input, InputNumber, Select } from 'antd';

import ConditionListView from 'components/ConditionListView';
import { Exchanges, Instruments, Segments } from 'types/constants';
import { PositionEditorProps } from 'types/props';
import { Condition, ConditionList, LogicOperator, Position } from 'types/types';
import { v4 as uuidv4 } from 'uuid';

const defaultPosition: Position = {
  id: uuidv4(),
  exchange: 'nse',
  segment: 'options',
  instrument: 'finnifty',
  multiplier: 1,
  lotCount: 1,
  strike: 'atm',
  contractType: 'ce',
  expiry: 'cur_week',
  exitConditions: { conditions: [], logic: LogicOperator.AND },
};

function PositionEditor(props: PositionEditorProps) {
  const [positionToDisplay, setPositionToDisplay] = React.useState<Position>(
    props.position || defaultPosition,
  );
  const [addStoplossPercentCondition, setAddStoplossPercentCondition] =
    React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);

  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
      }}
    >
      <Card>
        <Input.Group compact>
          <Select
            value={positionToDisplay.exchange}
            onChange={(value: string) => {
              setPositionToDisplay({
                ...positionToDisplay,
                exchange: value,
              });
            }}
          >
            {Object.keys(Exchanges).map((exchange: string) => (
              <Select.Option key={exchange} value={exchange}>
                {Exchanges[exchange as keyof typeof Exchanges]}
              </Select.Option>
            ))}
          </Select>
          <Select
            value={positionToDisplay.segment}
            onChange={(value: string) => {
              setPositionToDisplay({
                ...positionToDisplay,
                segment: value,
              });
            }}
          >
            {Object.keys(Segments).map((segment: string) => (
              <Select.Option key={segment} value={segment}>
                {Segments[segment as keyof typeof Segments]}
              </Select.Option>
            ))}
          </Select>
          <Select
            value={positionToDisplay.instrument}
            onChange={(value: string) => {
              setPositionToDisplay({
                ...positionToDisplay,
                instrument: value,
              });
            }}
          >
            {Object.keys(Instruments).map((instrument: string) => (
              <Select.Option key={instrument} value={instrument}>
                {Instruments[instrument as keyof typeof Instruments]}
              </Select.Option>
            ))}
          </Select>
          <Select
            value={positionToDisplay.multiplier}
            onChange={(value: number) => {
              setPositionToDisplay({
                ...positionToDisplay,
                multiplier: value,
              });
            }}
          >
            <Select.Option value={1}>BUY</Select.Option>
            <Select.Option value={-1}>SELL</Select.Option>
          </Select>
          <InputNumber
            value={positionToDisplay.lotCount}
            onChange={(value: number) => {
              setPositionToDisplay({
                ...positionToDisplay,
                lotCount: value,
              });
            }}
            addonBefore="Lot(s):"
          />
          <Select
            value={positionToDisplay.strike}
            onChange={(value: string) => {
              setPositionToDisplay({
                ...positionToDisplay,
                strike: value,
              });
            }}
          >
            <Select.Option value="atm">ATM</Select.Option>
          </Select>
          <Select
            value={positionToDisplay.contractType}
            onChange={(value: string) => {
              setPositionToDisplay({
                ...positionToDisplay,
                contractType: value,
              });
            }}
          >
            <Select.Option value="ce">CE</Select.Option>
            <Select.Option value="pe">PE</Select.Option>
          </Select>
          <Select
            value={positionToDisplay.expiry}
            onChange={(value: string) => {
              setPositionToDisplay({
                ...positionToDisplay,
                expiry: value,
              });
            }}
          >
            <Select.Option value="cur_week">Current week</Select.Option>
          </Select>
        </Input.Group>
        {positionToDisplay.exitConditions.conditions.length ? (
          <h4>Position exit conditions</h4>
        ) : null}
        <ConditionListView
          conditionList={positionToDisplay.exitConditions}
          setConditionList={(conditionList: ConditionList) => {
            setPositionToDisplay({
              ...positionToDisplay,
              exitConditions: conditionList,
            });
          }}
          showAddCondition={false}
          addStoplossPercentCondition={addStoplossPercentCondition}
          setAddStoplossPercentCondition={setAddStoplossPercentCondition}
          setIsEditing={(isEditing: boolean) => {
            setIsEditing(isEditing);
          }}
        />
        <div
          style={{
            padding: '10px',
            display: 'flex',
            gap: '5px',
          }}
        >
          <Button
            type="primary"
            disabled={isEditing}
            onClick={() => {
              props.setPosition(positionToDisplay);
            }}
          >
            SAVE
          </Button>
          <Button
            type="primary"
            disabled={isEditing}
            onClick={() => props.cancelEdit()}
          >
            CANCEL
          </Button>
          {!positionToDisplay.exitConditions.conditions.some(
            (condition: Condition) => condition.type === 'stoplossPercent',
          ) ? (
            <Button
              type="primary"
              disabled={isEditing}
              onClick={() => setAddStoplossPercentCondition(true)}
            >
              ADD STOPLOSS %
            </Button>
          ) : null}
        </div>
      </Card>
    </div>
  );
}

export default PositionEditor;
