export const Exchanges: { [key: string]: string } = {
  nse: 'NSE',
  // bse: 'BSE',
  // crypto: 'CRYPTO',
};

export const Segments: { [key: string]: string } = {
  options: 'OPTIONS',
};

export const Instruments: { [key: string]: string } = {
  finnifty: 'FINNITY',
};
