import { ConditionDropdownView, ConditionsMapType } from 'types/types';

import StoplossPercentEditor from './StoplossPercent/editor';
import StoplossPercentView from './StoplossPercent/view';
import StrategyTargetEditor from './StrategyTarget/editor';
import StrategyTargetView from './StrategyTarget/view';
import TimeEditor from './Time/editor';
import TimeView from './Time/view';

export const conditions: ConditionDropdownView[] = [
  { type: 'time', displayName: 'Time', Editor: TimeEditor, View: TimeView },
  {
    type: 'strategyTarget',
    displayName: 'Strategy Target',
    Editor: StrategyTargetEditor,
    View: StrategyTargetView,
  },
];

export const numberReturners: ConditionDropdownView[] = [
  // {
  //   type: 'pnl',
  //   displayName: 'PNL',
  //   details: 'PNL of the strategy',
  //   Editor: ExpressionEditor,
  //   View: TimeView,
  // },
];

export const positionConditions: ConditionDropdownView[] = [
  {
    type: 'stoplossPercent',
    displayName: 'Stoploss Percent',
    Editor: StoplossPercentEditor,
    View: StoplossPercentView,
  },
];

export const conditionsMap: ConditionsMapType = {
  ...conditions.reduce(
    (accumulator: ConditionsMapType, condition: ConditionDropdownView) => ({
      ...accumulator,
      [condition.type]: condition,
    }),
    {},
  ),
  ...numberReturners.reduce(
    (
      accumulator: ConditionsMapType,
      numberReturner: ConditionDropdownView,
    ) => ({
      ...accumulator,
      [numberReturner.type]: numberReturner,
    }),
    {} as { [key: string]: ConditionDropdownView },
  ),
  ...positionConditions.reduce(
    (
      accumulator: ConditionsMapType,
      numberReturner: ConditionDropdownView,
    ) => ({
      ...accumulator,
      [numberReturner.type]: numberReturner,
    }),
    {} as { [key: string]: ConditionDropdownView },
  ),
};
