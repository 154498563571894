import React, { useState } from 'react';

import { Button, Card } from 'antd';

import axios from 'axios';
import ConditionListView from 'components/ConditionListView';
import PositionListView from 'components/PositionListView';
import { ConditionList, LogicOperator, Position, Set } from 'types/types';

function TempNavBar() {
  return (
    <div
      style={{
        width: '100%',
        height: '50px',
        backgroundColor: 'black',
      }}
    ></div>
  );
}

async function runBacktest(sets: Set[]) {
  const backtestResult: any = await axios.post(
    'http://localhost:8080/backtest',
    {
      password: 'jishanth',
      strategy: { sets: sets, name: 'Dummy name' },
      startDate: '2022-10-01',
      endDate: '2022-12-31',
    },
  );
  console.log(backtestResult);
}

function Backtest() {
  const [sets, setSets] = useState<Set[]>([
    {
      name: 'Default Set',
      entry: {
        conditions: [],
        logic: LogicOperator.AND,
      },
      positions: [],
      adjustments: [],
      exit: {
        conditions: [],
        logic: LogicOperator.AND,
      },
    },
  ]);

  return (
    <>
      <TempNavBar />
      <Card style={{}}>
        {sets.map((set: Set, setIndex: number) => (
          <>
            {/* <h2>{set.name}</h2> */}
            <h3>Entry conditions</h3>
            <Card>
              <ConditionListView
                conditionList={set.entry}
                setConditionList={(conditionList: ConditionList) => {
                  setSets([
                    ...sets.slice(0, setIndex),
                    {
                      ...sets[setIndex],
                      entry: conditionList,
                    },
                    ...sets.slice(setIndex + 1),
                  ]);
                }}
                showAddCondition
              />
            </Card>
            <h3>Positions</h3>
            <PositionListView
              positionList={set.positions}
              setPositionList={(positionList: Position[]) => {
                setSets([
                  ...sets.slice(0, setIndex),
                  {
                    ...sets[setIndex],
                    positions: positionList,
                  },
                  ...sets.slice(setIndex + 1),
                ]);
              }}
            />
            {/* <h3>Adjustments</h3> */}
            <h3>Exit conditions</h3>
            <Card>
              <ConditionListView
                conditionList={set.exit}
                setConditionList={(conditionList: ConditionList) => {
                  setSets([
                    ...sets.slice(0, setIndex),
                    {
                      ...sets[setIndex],
                      exit: conditionList,
                    },
                    ...sets.slice(setIndex + 1),
                  ]);
                }}
                showAddCondition
              />
            </Card>
          </>
        ))}
        <Button onClick={() => runBacktest(sets)}>BACKTEST</Button>
      </Card>
    </>
  );
}

export default Backtest;
