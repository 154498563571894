import React, { useState } from 'react';

import { Button, Card, Input, Select } from 'antd';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  conditions,
  conditionsMap,
  numberReturners,
} from 'components/Conditions/conditionList';
import { ConditionEditorProps, EditorProps } from 'types/props';
import { Condition, ConditionDropdownView } from 'types/types';
import { v4 as uuidv4 } from 'uuid';

function Editor(props: EditorProps) {
  const ConditionEditorComponent: React.FC<EditorProps> =
    conditionsMap[props.condition.type].Editor;
  return <ConditionEditorComponent {...props} />;
}

function ConditionEditor(props: ConditionEditorProps) {
  const [conditionToDisplay, setConditionToDisplay] =
    useState<Condition | null>(props.condition);
  const [enabled, setEnabled] = React.useState(false);

  React.useEffect(() => {
    if (props.addStoplossPercentCondition) {
      props.setAddStoplossPercentCondition(false);
      setConditionToDisplay({
        id: uuidv4(),
        type: 'stoplossPercent',
      });
    }
  }, [props.addStoplossPercentCondition]);

  return (
    <Card>
      <div
        style={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <span>
          <Input.Group compact>
            {!conditionToDisplay ? (
              <Select
                style={{
                  minWidth: '100px',
                }}
                placeholder="Select a condition"
                onChange={(value: string) => {
                  setConditionToDisplay({
                    id: uuidv4(),
                    type: value,
                  });
                }}
              >
                {conditions.map((condition: ConditionDropdownView) => (
                  <Select.Option key={condition.type} value={condition.type}>
                    {condition.displayName}
                  </Select.Option>
                ))}
                {numberReturners.map(
                  (numberReturner: ConditionDropdownView) => (
                    <Select.Option
                      key={numberReturner.type}
                      value={numberReturner.type}
                    >
                      {numberReturner.displayName}
                    </Select.Option>
                  ),
                )}
              </Select>
            ) : null}
            {conditionToDisplay ? (
              <Editor
                condition={conditionToDisplay}
                setEnable={(enabled: boolean) => setEnabled(enabled)}
                setCondition={(condition: Condition) =>
                  setConditionToDisplay(condition)
                }
              ></Editor>
            ) : null}
          </Input.Group>
        </span>
        <Button
          type="primary"
          disabled={!enabled}
          icon={<CheckOutlined />}
          onClick={() => {
            props.setCondition(conditionToDisplay);
          }}
        />
        <Button
          type="primary"
          icon={<CloseOutlined />}
          onClick={() => props.cancelEdit()}
        />
      </div>
    </Card>
  );
}

export default ConditionEditor;
