import React, { useState } from 'react';

import { Button, Card } from 'antd';

import PositionView from 'components/PositionView';
import { PositionListViewProps } from 'types/props';
import { Position } from 'types/types';

import PositionEditor from '../PositionEditor';

import './style.scss';

function PositionListView({
  positionList,
  setPositionList,
}: PositionListViewProps) {
  const [addingPosition, setAddingPosition] = useState<boolean>(false);
  const [editingPositionIndex, setEditingPositionIndex] = useState<
    number | null
  >(null);
  return (
    <Card>
      {positionList.map((position: Position, positionIndex: number) => (
        <div
          key={position.id}
          style={{
            display: 'flex',
            gap: '10px',
          }}
        >
          {editingPositionIndex === positionIndex ? (
            <PositionEditor
              position={position}
              setPosition={(position: Position) => {
                setPositionList([
                  ...positionList.slice(0, positionIndex),
                  position,
                  ...positionList.slice(positionIndex + 1),
                ]);
                setEditingPositionIndex(null);
              }}
              cancelEdit={() => {
                setEditingPositionIndex(null);
              }}
            />
          ) : (
            <Card>
              <PositionView position={position} />
              <div
                style={{
                  padding: '10px',
                }}
              >
                <Button
                  type="primary"
                  disabled={editingPositionIndex !== null}
                  onClick={() => {
                    setEditingPositionIndex(positionIndex);
                  }}
                >
                  EDIT POSITION
                </Button>
                <Button
                  type="primary"
                  danger
                  disabled={editingPositionIndex !== null}
                  onClick={() => {
                    setPositionList([
                      ...positionList.slice(0, positionIndex),
                      ...positionList.slice(positionIndex + 1),
                    ]);
                  }}
                >
                  DELETE POSITION
                </Button>
              </div>
            </Card>
          )}
        </div>
      ))}
      {addingPosition ? (
        <PositionEditor
          position={null}
          setPosition={(position: Position) => {
            setPositionList([...positionList, position]);
            setAddingPosition(false);
          }}
          cancelEdit={() => {
            setAddingPosition(false);
          }}
        />
      ) : null}
      <Button
        disabled={editingPositionIndex !== null || addingPosition}
        onClick={() => {
          setAddingPosition(true);
        }}
      >
        ADD POSITION
      </Button>
    </Card>
  );
}

export default PositionListView;
