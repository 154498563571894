import React from 'react';

import { Button } from 'antd';

import ConditionListView from 'components/ConditionListView';
import { Exchanges, Instruments, Segments } from 'types/constants';
import { PositionViewProps } from 'types/props';
import { ConditionList } from 'types/types';

function ViewBlock(props: any): JSX.Element {
  return (
    <Button
      {...props}
      style={{
        cursor: 'initial',
      }}
    ></Button>
  );
}

function PositionView(props: PositionViewProps) {
  return (
    <div>
      <ViewBlock>
        {Exchanges[props.position.exchange as keyof typeof Exchanges]}
      </ViewBlock>
      <ViewBlock>
        {Segments[props.position.segment as keyof typeof Segments]}
      </ViewBlock>
      <ViewBlock>
        {Instruments[props.position.instrument as keyof typeof Instruments]}
      </ViewBlock>
      <ViewBlock>{props.position.multiplier === 1 ? 'BUY' : 'SELL'}</ViewBlock>
      <ViewBlock>{`${props.position.lotCount} lot(s)`}</ViewBlock>
      <ViewBlock>ATM</ViewBlock>
      <ViewBlock>
        {props.position.contractType === 'ce' ? 'CE' : 'PE'}
      </ViewBlock>
      <ViewBlock>Current week</ViewBlock>
      {props.position.exitConditions.conditions.length ? (
        <h4>Position exit conditions</h4>
      ) : null}
      <ConditionListView
        conditionList={props.position.exitConditions}
        readonly
        setConditionList={(conditionList: ConditionList) => {
          throw new Error('Function not implemented.');
        }}
      />
    </div>
  );
}

export default PositionView;
