import React, { useState } from 'react';

import { Select, TimePicker } from 'antd';

import dayjs, { Dayjs } from 'dayjs';
import { EditorProps } from 'types/props';

import './editor.scss';

const options: string[] = ['before', 'after'];

function TimeEditor(props: EditorProps) {
  const [time, setTime] = useState(props.condition.time);
  const [beforeOrAfter, setBeforeOrAfter] = useState(
    props.condition.beforeOrAfter,
  );

  React.useEffect(() => {
    props.setEnable(!!time && !!beforeOrAfter);
    props.setCondition({
      ...props.condition,
      time: time,
      beforeOrAfter: beforeOrAfter,
    });
    // TODO: Fix the lint error of next line
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, beforeOrAfter]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <span>Time is</span>
      <Select
        placeholder="Click"
        value={beforeOrAfter}
        onChange={(value: string) => {
          setBeforeOrAfter(value);
        }}
        options={options.map((option: string) => ({
          label: option,
          value: option,
        }))}
      ></Select>
      <TimePicker
        style={{
          paddingLeft: '2px',
          paddingRight: '2px',
        }}
        value={time ? dayjs(time, 'HH:mm:ss') : null}
        use12Hours
        onSelect={(value: Dayjs) => {
          setTime(value.format('HH:mm:ss'));
        }}
      />
    </div>
  );
}

export default TimeEditor;
