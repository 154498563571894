import React, { useEffect } from 'react';

import { InputNumber, Space } from 'antd';

import { EditorProps } from 'types/props';

function StrategyTargetEditor(props: EditorProps) {
  const [value, setValue] = React.useState(
    props.condition.value !== null ? Number(props.condition.value) : null,
  );

  const isNumberValidPnl: (num: number) => boolean = (num: number) =>
    num !== null && num !== undefined && !Number.isNaN(num);

  useEffect(() => {
    props.setCondition({
      ...props.condition,
      value: isNumberValidPnl(value) ? value.toString() : null,
    });
    props.setEnable(isNumberValidPnl(value));
  }, [value]);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Space direction="vertical">
        <InputNumber
          value={value}
          onChange={(value: number) => {
            setValue(value);
          }}
          type="number"
          min={0}
          max={Number.MAX_SAFE_INTEGER}
          addonBefore="Strategy Target is "
        />
      </Space>
    </div>
  );
}

export default StrategyTargetEditor;
