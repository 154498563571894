import React from 'react';

export enum LogicOperator {
  OR,
  AND,
}

export enum Segment {}

export interface Condition {
  id: string;
  type: string;
  [key: string]: string;
}

export interface ConditionList {
  conditions: Condition[];
  logic: LogicOperator;
}

export interface Position {
  id: string;
  exchange: string;
  segment: string;
  instrument: string;
  multiplier: number;
  lotCount: number;
  strike: string;
  contractType: string;
  expiry: string;
  exitConditions: ConditionList;
}

export interface Adjustment {
  conditions: ConditionList;
  positions: Position[];
}

export interface Set {
  name: string;
  entry: ConditionList;
  positions: Position[];
  adjustments: Adjustment[];
  exit: ConditionList;
}

export interface ConditionDropdownView {
  type: string;
  displayName: string;
  Editor: React.FC;
  View: React.FC;
  details?: string;
}

export interface ConditionsMapType {
  [key: string]: ConditionDropdownView;
}
